/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


var product = function () {
    var appproduct = null;
    var quantidadeImagem = null;
    var indexImagem = 0;

//    // inisilizia os silder das imagem
    var intevent = function () {
        $(".product-slider-slides img").first().addClass("active");
    }
    /**
     * Para tratamento de eventos...
     * */

    var getCookie = function (name) {
        function escape(s) {
            return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1');
        }
        var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
        return match ? match[1] : null;
    }

//    var stopDefAction = 




    var validateEmail = function (email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    var eventHandler = function () {




        // ABRBR CAIXA INFORMATIVA DO PRODUTO
        $(".slide-info", appproduct).click(function () {
            var produtInf = $('.product-info', appproduct);
            produtInf.addClass("active");
        });

        // FECHA CAIXA INFORMATIVA DO PRODUTO
        $(".slide-close", appproduct).click(function () {
            var produtInf = $('.product-info', appproduct);
            produtInf.removeClass("active");
        });


        // TRATAMENTO DO CLICK DO BOTAO FULL-SCREN
        $(".product-slider-options div .slide-full", appproduct).click(function () {
            $(".product-slider").toggleClass('fullscreen');

        });  // TRATAMENTO DO CLICK DO BOTAO FULL-SCREN
        $(".product-info-close", appproduct).click(function () {
            $(".product-slider").toggleClass('fullscreen');

        });

        //BUTAO SILDER PREV
        $(".slide-next", appproduct).click(function () {
            $(".slide-prev").attr("class", "slide-prev");

            $('.product-slider .product-slider-slides img').eq(indexImagem).removeClass("active");
//             console.log(indexImagem , quantidadeImagem);
            if (indexImagem < quantidadeImagem)
            {
                indexImagem++;
//                console.log(indexImagem , quantidadeImagem);
                if (indexImagem === quantidadeImagem) {
                    $(".slide-next").attr("class", "slide-next disabled");

                }
            } else if (indexImagem >= quantidadeImagem) {
                indexImagem = quantidadeImagem;

            } else
            {
                $(".slide-next").attr("class", "slide-next disabled");
//                indexImagem = 0;
            }
            $('.product-slider .product-slider-slides img').eq(indexImagem).addClass("active");
        });

        //BUTAO SILDER PREV
        $(".slide-prev", appproduct).click(function () {
            $(".slide-next").attr("class", "slide-next");
            $('.product-slider .product-slider-slides img').eq(indexImagem).removeClass("active");
            if (indexImagem > 0)
            {
                indexImagem--;
                console.log(indexImagem, quantidadeImagem);
                if (1 === quantidadeImagem) {
                    $(".slide-prev").attr("class", "slide-prev disabled");
                }
            }
            $('.product-slider .product-slider-slides img').eq(indexImagem).addClass("active");
        });




        let  popupNews = document.getElementById("pop-up");
        if (popupNews !== null) {
            if (getCookie("PopupNews") === null) {
                popupNews.classList.add("on");
            }

            var popupclsoe = document.getElementsByClassName("pop-up-close");
            popupclsoe[0].onclick = function () {
                popupNews.classList.remove("on");
                var d = new Date();
                d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000));
                var expires = "expires=" + d.toUTCString();
                console.log(expires);
                document.cookie = "PopupNews=1;" + expires;
            }
        }

        $(".button-pc").click(function (evt) {
            evt.preventDefault();
        var lang = window.location.pathname.split('/', 2);
        var erromes = new Array;
        erromes["pt"] = {
            0: "Enviado com Sucesso",
            1: "Termos obrigatrios",
            2: "E-mail não valido",
            3: "Ocorreu um erro tente mais tarde",
        };
        erromes["en"] = {
            0: "Sent with success",
            1: "Mandatory terms",
            2: "Email not valid",
            3: "An error occurred try later",
        };
        erromes["fr"] = {
            0: "Envoyé avec succès",
            1: "Termes obligatoires",
            2: "email non valide",
            3: "Une erreur s'est produite, essayez plus tard",
        };
        erromes["es"] = {
            0: "Enviado con éxito",
            1: "Términos obligatorios",
            2: "Correo electrónico no válida",
            3: "Se produjo un error, intente más tarde",
        };
        
        var popupInfo = document.querySelector(".pop-input"),
                popcheck = document.querySelector(".pop-check"),
                popurl = document.querySelector(".popupform"),
                _token = document.getElementsByName("_token")[0].value
        var popres = document.getElementsByClassName("popup-erro")[0];

        if (popcheck.checked)
        {
            if (validateEmail(popupInfo.value))
            {
                const url = popurl.getAttribute("action");
                var http = new XMLHttpRequest();
                var params = '_token='+_token+'&email=' + popupInfo.value + '&check=' + popcheck.checked;
                http.open('POST', url, true);
                http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                http.onreadystatechange = function () {
                    //Call a function when the state changes.
                    console.log(window.location);
                    if (http.readyState == 4 && http.status == 200) {
                        var obj = JSON.parse(http.responseText);
                        if (obj.res) {
                            popres.innerHTML = erromes[lang[1]][0];
                        } else {
                            popres.innerHTML = erromes[lang[1]][obj.msg];
                        }
                    }
                }
                http.send(params);
            } else {
                popres.innerHTML = erromes[lang[1]][2];
            }
        } else {
            popres.innerHTML = erromes[lang[1]][1];
        }
    });
    

    
        $(".button-mb").click(function (evt) {
            evt.preventDefault();
            
            
              $(".button-pc").trigger("click");
        });
//        popupSub[0].addEventListener('click', stopDefAction, false);
//        popupSub2[0].addEventListener('click', stopDefAction, false);


    };
    return {
        init: function (_appproduct) {


            appproduct = _appproduct;
            quantidadeImagem = $(".product-slider .product-slider-slides img").length - 1;

            intevent();

            eventHandler();
        }
    };
}();

product.init($('.product-presentation'));
$(function () {
$('[data-toggle="popover"]').popover({
    placement: 'top',
    trigger: 'focus, hover'
})
})